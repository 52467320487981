import React, { useState } from "react"
import styled from "styled-components"
import { Box, Text } from "grommet"
import { slide as Menu } from "react-burger-menu"
import { HamburgerCollapse } from "react-animated-burgers"
import { LinkedinOption, Twitter } from "grommet-icons"

import { theme } from "../styles/theme"

import "../styles/layout.css"
import { H1 } from "../components/Typography"
import IconButton from "./Buttons/IconButton"
import SidebarMenu from "./Sidebar/Menu"
import LanguageSelector from "./Menus/LanguageSelector"
import Link from "./Link/Link"
import PeriPlusLogo from "../assets/PeriPlusAG.svg"

const NavbarBox = styled(Box)`
  box-shadow: 0px 66px 24px #e7e4ff;
  &:hover {
    box-shadow: 0px 6px 24px #dad6fa;
  }
`

const Sidebar = () => {
  const [isActive, setIsActive] = useState(false)

  const closeAllMenusOnEsc = e => {
    e = e || window.event

    if (e.key === "Escape" || e.keyCode === 27) {
      setIsActive(false)
    }
  }

  if (isActive) {
    window.onscroll = function() {
      window.scrollTo(0, 0)
    }
  }

  if (!isActive) {
    window.onscroll = function() {}
  }

  return (
    <>
      <HamburgerCollapse
        style={{ zIndex: 3000000, display: "flex" }}
        isActive={isActive}
        buttonWidth={30}
        toggleButton={() => setIsActive(!isActive)}
        barColor={
          isActive
            ? theme.global.colors["paragraphDark"]
            : theme.global.colors["paragraphLight"]
        }
      />
      <Menu
        pageWrapId={"page-wrap"}
        isOpen={isActive}
        customBurgerIcon={false}
        customCrossIcon={false}
        customOnKeyDown={closeAllMenusOnEsc}
        outerContainerId={"outer-container"}
        disableOverlayClick={() => setIsActive(false)}
        right
      >
        <NavbarBox
          direction="column"
          flex="grow"
          pad="large"
          background="#211e32"
          justify="between"
          align="center"
        >
          <Box width="full" align="start">
            <PeriPlusLogo style={{ width: 100, marginTop: -5 }} />
          </Box>

          <Box direction="column" justify="end" width="full">
            <Box margin={{ bottom: "20%" }} align="center">
              <Link>
                <H1 color="inherit" margin={{ vertical: "medium" }}>
                  seo:home
                </H1>
              </Link>

              <SidebarMenu />
            </Box>

            <Box direction="row" width="100%">
              <Box basis="1/2">
                <Box direction="row">
                  <Box pad={{ right: "large" }}>
                    <a
                      href="https://twitter.com/PeriPlusAG"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconButton>
                        <Twitter />
                      </IconButton>
                    </a>
                  </Box>
                  <Box>
                    <a
                      href="https://linkedin.com/company/periplus-ag"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconButton>
                        <LinkedinOption />
                      </IconButton>
                    </a>
                  </Box>
                </Box>
                <Box
                  direction="column"
                  justify="center"
                  pad={{ vertical: "medium" }}
                >
                  <Text size="xsmall">
                    © {new Date().getFullYear()} PeriPlus AG.
                  </Text>
                </Box>
              </Box>

              <Box align="start" justify="end" basis="1/2" direction="row">
                <Box>
                  <LanguageSelector small />
                </Box>
              </Box>
            </Box>
          </Box>
        </NavbarBox>
      </Menu>
    </>
  )
}

export default Sidebar
