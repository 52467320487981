export const companyData = {
  name: "PeriPlus AG",
  adress: {
    street: "Münchensteinerstrasse 274",
    zip: "4053",
    place: "Basel-Stadt",
    country: "Schweiz",
    telephon: "",
    uid: "CHE-310.499.588",
    mwst: "CHE-310.499.588 MWST",
  },
  employees: {
    number: 12,
    name: "Perineers",
  },
}
