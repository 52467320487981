import React, { useState } from "react"
import styled from "styled-components"
import { Box, ResponsiveContext } from "grommet"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

import Link from "./Link/Link"
import { theme } from "../styles/theme"
import PeriPlusLogo from "../assets/PeriPlusAG.svg"
import PeriPlusLogoDark from "../assets/PeriPlusLogo_Dark.svg"
import Sidebar from "../components/Sidebar"
import LanguageSelector from "./Menus/LanguageSelector"
import DropDown from "./Menus/DropDown"
// import AditIcon from "../assets/AditIcon.svg"
import SpoorIcon from "../assets/SpoorIcon.svg"
import WeDeclareIcon from "../assets/WeDeclareIcon.svg"

const NavbarBox = styled(Box)`
  position: fixed;
  z-index: 3;
  width: 100%;
  background: ${props => (props.background ? "white" : "transparent")};
  box-shadow: ${props =>
    props.background ? theme.global.shadows.lightPurple : null};
  transition: 0.2s;
`

const Header = () => {
  const [showBackground, setshowBackground] = useState(false)

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y > -35) {
        setshowBackground(false)
      } else {
        setshowBackground(true)
      }
    },
    [showBackground]
  )

  const menu = [
    {
      icon: <WeDeclareIcon />,
      linkDesc: "products:weDeclareSubtitle",
      title: "WeDeclare",
      path: "https://www.wedeclare.ch",
    },
    {
      icon: <SpoorIcon />,
      linkDesc: "products:spoorSubtitle",
      title: "Spoor",
      path: "https://www.spoortracking.ch",
    },
  ]

  const linkColor = showBackground
    ? theme.global.colors["dark-1"]
    : theme.global.colors["light-2"]
  console.log(
    "🚀 ~ file: Header.js ~ line 49 ~ Header ~ showBackground",
    showBackground
  )
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <NavbarBox
          background={showBackground}
          direction="row"
          justify="center"
          height={showBackground ? "65px" : "75px"}
        >
          <Box
            width="xlarge"
            justify="center"
            pad={
              size === "large"
                ? { horizontal: "none" }
                : { horizontal: "medium" }
            }
          >
            <Box direction="row" pad={{ horizontal: "small" }}>
              <Box alignSelf="center" basis="1/4">
                <Link to="/" style={{ zIndex: 200, display: "flex" }}>
                  {showBackground ? (
                    <PeriPlusLogoDark
                      style={size === "small" ? { width: 100 } : { width: 130 }}
                    />
                  ) : (
                    <PeriPlusLogo
                      style={size === "small" ? { width: 100 } : { width: 130 }}
                    />
                  )}
                </Link>
              </Box>

              {size === "small" ? null : (
                <>
                  <Box justify="center" basis="2/4" direction="row">
                    <Box pad="medium" justify="center">
                      <Link
                        color={linkColor}
                        whiteBackground={showBackground}
                        to="/"
                        internal
                        label="home"
                      />
                    </Box>

                    <DropDown
                      whiteBackground={showBackground}
                      label="products"
                      color={linkColor}
                      data={menu}
                    />
                  </Box>
                  <Box align="center" justify="end" basis="1/4" direction="row">
                    <LanguageSelector
                      whiteBackground={showBackground}
                      color={linkColor}
                    />
                  </Box>
                </>
              )}
              {size === "small" ? (
                <Box basis="full" justify="end" direction="row" align="center">
                  <Sidebar />
                </Box>
              ) : null}
            </Box>
          </Box>
        </NavbarBox>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Header
