import React, { useState } from "react"
import { Box, Accordion, AccordionPanel } from "grommet"
import { FormDown } from "grommet-icons"

import { H1, H2 } from "../Typography"
import Link from "../Link/Link"

const renderPanelHeader = title => {
  return (
    <Box
      pad={{ vertical: "medium" }}
      direction="row"
      justify="start"
      align="center"
    >
      <Link>
        <H1 color="inherit" margin={{ vertical: "small" }}>
          {title}
        </H1>
      </Link>
      <Box margin={{ left: "small" }}>
        <FormDown color="white" />
      </Box>
    </Box>
  )
}

const SidebarMenu = () => {
  const [activeIndex, setActiveIndex] = useState([])

  return (
    <Accordion
      activeIndex={activeIndex}
      open
      onActive={newActiveIndex => setActiveIndex(newActiveIndex)}
      animate
    >
      <AccordionPanel
        header={renderPanelHeader(
          "navigation:products",
          activeIndex.includes(1)
        )}
      >
        <Box overflow="hidden">
          <Box flex={false}>
            <Box pad={{ bottom: "medium" }} align="center">
              <Link
                to="https://www.wedeclare.ch"
                target="_blank"
                rel="noopener noreferrer"
                key="products:weDeclareSubtitle"
              >
                <H2 color="inherit" margin={{ vertical: "small" }}>
                  WeDeclare
                </H2>
              </Link>
              <Link
                href="https://www.spoortracking.ch"
                target="_blank"
                rel="noopener noreferrer"
                key="products:spoorSubtitle"
              >
                <H2 color="inherit" margin={{ vertical: "small" }}>
                  Spoor
                </H2>
              </Link>
            </Box>
          </Box>
        </Box>
      </AccordionPanel>
    </Accordion>
  )
}

export default SidebarMenu
