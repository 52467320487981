import React from "react"
import { Box } from "grommet"

const TriangleSection = ({
  size,
  variant = "small",
  bottom = "8px",
  left = 0,
  top = "auto",
  ...rest
}) => {
  const small = {
    small: {
      left: left,
      top: top,
      height: "1.5rem",
      transform: "matrix(-1, -0.06, 0, 1, 0, 0)",
    },
    medium: {
      left: left,
      top: top,
      height: "2rem",
      transform: "matrix(-1, -0.03, 0, 1, 0, 0)",
    },
    large: {
      left: left,
      top: top,
      height: "2rem",
      transform: "matrix(-1, -0.02, 0, 1, 0, 0)",
    },
    xlarge: {
      left: left,
      top: top,
      height: "2rem",
      transform: "matrix(-1, -0.02, 0, 1, 0, 0)",
    },
  }

  const medium = {
    small: {
      bottom: bottom,
      left: left,
      top: top,
      height: "1.5rem",
      transform: "matrix(-1, -0.04, 0, 1, 0, 0)",
    },
    medium: {
      bottom: bottom,
      left: left,
      top: top,
      height: "2.5rem",
      transform: "matrix(-1, -0.03, 0, 1, 0, 0)",
    },
    large: {
      bottom: bottom,
      left: left,
      top: top,
      height: "2.5rem",
      transform: "matrix(-1, -0.02, 0, 1, 0, 0)",
    },
    xlarge: {
      bottom: bottom,
      left: left,
      top: top,
      height: "3rem",
      transform: "matrix(-1, -0.01, 0, 1, 0, 0)",
    },
  }

  const dimensions = {
    width: "100vw",
    bottom: bottom,
    position: "absolute",
  }

  return variant === "small" ? (
    <Box
      style={{
        ...dimensions,
        ...small[size],
        background:
          "linear-gradient(337.22deg,rgba(233,230,246,0) 7.19%,rgba(233,230,246,.5) 55.82%,rgba(233,230,246,0) 102.45%)",
      }}
      {...rest}
    />
  ) : (
    <Box
      style={{
        ...dimensions,
        ...medium[size],
        background:
          "linear-gradient(90.01deg, rgba(196, 196, 196, 0.08) -0.01%, rgba(235, 242, 255, 0.16) 42.03%, rgba(196, 196, 196, 0.16) 76.85%)",
      }}
      {...rest}
    />
  )
}

export default TriangleSection
