import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ResponsiveContext } from "grommet"

import "../styles/layout.css"
import "../i18n"

import Header from "./Header"
import Footer from "./Footer"
import Cookies from "./Dialog/Cookies"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteMetaQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Cookies size={size} />
          <div
            id="outer-container"
            style={{ height: "100%", overflow: "hidden" }}
          >
            <Header
              name="header"
              screenSize="small"
              title={data.site.siteMetadata.title}
            />
            <main
              id="page-wrap"
              style={{ height: "100%", overflowX: "hidden" }}
            >
              {children}

              <Footer name="footer" />
            </main>
          </div>
        </>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Layout
