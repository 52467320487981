import React from "react"
import { Box } from "grommet"

const TriangleBottom = ({
  bottom = "-0.25px",
  left = 0,
  zIndex = 11,
  color = "white",
}) => {
  return (
    <Box
      style={{
        bottom: bottom,
        left: left,
        borderColor: `transparent transparent transparent ${color}`,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: "100vw",
        borderTopWidth: "2rem",
        position: "absolute",
        borderStyle: "solid",
        zIndex: zIndex,
      }}
    />
  )
}

export default TriangleBottom
