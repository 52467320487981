import React from "react"
import styled from "styled-components"
import { Box } from "grommet"

const StyledBox = styled(Box)`
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(112, 211, 233, 0.03)),
    color-stop(69%, rgba(115, 113, 225, 0.02)),
    to(rgba(255, 255, 255, 0))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(112, 211, 233, 0.03) 0,
    rgba(115, 113, 225, 0.02) 69%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(112, 211, 233, 0.03) 0,
    rgba(115, 113, 225, 0.02) 69%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(112, 211, 233, 0.03) 0,
    rgba(115, 113, 225, 0.02) 69%,
    rgba(255, 255, 255, 0) 100%
  );
`

const RectangleGrayBox = styled(Box)`
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(112, 211, 233, 0)),
    color-stop(69%, rgba(115, 113, 225, 0.02)),
    to(rgba(255, 255, 255, 0))
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(112, 211, 233, 0) 0px,
    #bdc3c71c 69%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -o-linear-gradient(
    90deg,
    rgba(112, 211, 233, 0) 0px,
    #bdc3c71c 69%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(112, 211, 233, 0) 0px,
    #bdc3c71c 69%,
    rgba(255, 255, 255, 0) 100%
  );
`

const RectangleDarkGrayBox = styled(Box)`
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(112, 211, 233, 0.03)),
    color-stop(69%, rgba(115, 113, 225, 0.02)),
    to(rgba(255, 255, 255, 0))
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(112, 211, 233, 0) 0px,
    rgb(189 195 199 / 18%) 69%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -o-linear-gradient(
    90deg,
    rgba(112, 211, 233, 0) 0px,
    rgb(189 195 199 / 18%) 69%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(112, 211, 233, 0) 0px,
    rgb(189 195 199 / 18%) 69%,
    rgba(255, 255, 255, 0) 100%
  );
`

export const RectangleLight = ({ degrees = 12 }) => {
  return (
    <StyledBox
      style={{
        top: "-8.125rem",
        right: 0,
        left: 0,
        height: "25rem",
        transform: `rotate(${degrees}deg)`,
        position: "absolute",
      }}
    />
  )
}

export const RectangleGray = ({ degrees = 12, top, height = "45rem" }) => {
  return (
    <RectangleGrayBox
      style={{
        top: top,
        right: 0,
        left: 0,
        height: height,
        transform: `rotate(${degrees}deg)`,
        position: "absolute",
      }}
    />
  )
}

export const RectangleDarkerGray = ({ degrees = 12, top }) => {
  return (
    <RectangleDarkGrayBox
      style={{
        top: top,
        right: 0,
        left: 0,
        height: "45rem",
        transform: `rotate(${degrees}deg)`,
        position: "absolute",
      }}
    />
  )
}
