import React, { useRef, useState } from "react"
import { Box, Drop } from "grommet"
import { FormDown } from "grommet-icons"
import { useTranslation } from "react-i18next"

import { theme } from "../../styles/theme"
import { P } from "../Typography"
import Link from "../Link/Link"

const LanguageSelector = ({ color }) => {
  const [over, setOver] = useState()
  const ref = useRef()

  const { i18n } = useTranslation()

  const changeLanguage = value => {
    setOver(false)
    i18n.changeLanguage(value)
  }

  const languages = [
    {
      name: "English",
      value: "en",
    },
    {
      name: "Deutsch",
      value: "de",
    },
  ]

  const initiateLang = i18n.language === "de" ? "DE" : "EN"

  return (
    <Box
      ref={ref}
      onMouseOver={() => setOver(true)}
      onMouseLeave={() => setOver(false)}
      onFocus={() => {}}
      onBlur={() => {}}
      style={{ cursor: "pointer" }}
    >
      <Box direction="row" justify="center" align="center">
        <P color={color} bold style={{ margin: 0, fontSize: "14px" }}>
          {initiateLang}
        </P>
        <Box margin={{ left: "small" }} justify="center">
          <FormDown color={color} />
        </Box>
      </Box>

      {ref.current && over && (
        <Drop
          align={{ top: "bottom", right: "right" }}
          target={ref.current}
          style={{ marginTop: "0px", overflow: "initial" }}
          plain
          onClickOutside={() => setOver(false)}
        >
          <Box
            background="white"
            round="xsmall"
            pad="small"
            gap="xsmall"
            style={{
              border: "1px solid #f4f7fa",
              boxShadow: theme.global.shadows.float,
            }}
            onFocus={() => {}}
          >
            {languages.map((item, i) => {
              return (
                <Link
                  color={theme.global.colors["dark-1"]}
                  label={item.name}
                  onClick={() => changeLanguage(item.value)}
                />
              )
            })}
          </Box>
        </Drop>
      )}
    </Box>
  )
}

export default LanguageSelector
