import React from "react"
import { Box } from "grommet"
import CookieConsent from "react-cookie-consent"
import { useTranslation } from "react-i18next"

import { theme } from "../../styles/theme"
import { P } from "../Typography"
import Wrapper from "../Container/Wrapper"

const Cookies = ({ location = "bottom", size }) => {
  const { t } = useTranslation()
  const declineButton = {
    marginRight: "0px",
    marginTop: "0px",
    marginBottom: "0px",
    borderRadius: theme.global.control.border.radius,
    padding: "8px 12px",
    lineHeight: 1.55,
    fontSize: "14px",
  }
  return (
    <CookieConsent
      location={location}
      buttonText={t("cookie:accept")}
      enableDeclineButton
      contentClasses="cookieContent"
      buttonWrapperClasses="cookieButtons"
      style={{
        background: "white",
        color: "2px 2px black",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
      buttonStyle={{
        background: theme.global.colors.brand,
        color: "white",
        borderRadius: theme.global.control.border.radius,
        ...declineButton,
      }}
      declineButtonStyle={
        size !== "large"
          ? {
              marginLeft: "15px",
              ...declineButton,
            }
          : {
              margin: "0px",
              ...declineButton,
            }
      }
      declineButtonText={t("cookie:decline")}
      cookieName="gatsby-gdpr-google-analytics"
    >
      <Box align="center" pad="none">
        <Box width="xlarge" pad="none">
          <Wrapper horizontal="small" top="none" bottom="none">
            <P size="small" textAlign="center">
              cookie:message
            </P>
          </Wrapper>
        </Box>
      </Box>
    </CookieConsent>
  )
}

export default Cookies
