import React from "react"
import { Box, ResponsiveContext, Text } from "grommet"
import { LinkedinOption, Twitter } from "grommet-icons"
import TrustpilotReviews from "@pittica/gatsby-plugin-trustpilot-widget"

import { companyData } from "../hooks/useGetCompanyData"

import Link from "./Link/Link"
import IconButton from "./Buttons/IconButton"
import { theme } from "../styles/theme"
import { RectangleLight } from "./Elements/RectangleLight"
import { FooterTitle, P } from "../components/Typography"
import PeriPlusLogo from "../assets/PeriPlusAG.svg"

const centerJustificationRow = size => (size === "small" ? "center" : "end")
const centerAlignCol = size => (size === "small" ? "center" : "start")

const IconLink = ({ children, route }) => (
  <a
    href={route}
    target="_blank"
    rel="noopener noreferrer"
    style={{ zIndex: 2 }}
  >
    <IconButton hoverIndicator="white">{children}</IconButton>
  </a>
)

const PText = ({ children }) => (
  <P
    margin={{ top: "none", bottom: "none" }}
    style={{ lineHeight: "24px" }}
    size="small"
    color="footer"
  >
    {children}
  </P>
)

const ItemBox = ({ children, size }) => {
  const footerBox = {
    small: "full",
    medium: "1/3",
    large: "1/3",
  }

  return (
    <Box basis={footerBox[size]} align={size === "small" ? "center" : "start"}>
      {children}
    </Box>
  )
}

const Footer = () => {
  const { street, zip, place, uid, country } = companyData.adress
  const footerLink = { fontWeight: 300, fontSize: 14 }

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          background={theme.global.gradients.purpleBlueDark}
          direction="row"
          justify="center"
          style={{ position: "relative", overflow: "hidden" }}
        >
          <RectangleLight />

          <Box
            width="xlarge"
            direction="column"
            pad={
              size === "large"
                ? { horizontal: "none" }
                : { horizontal: "medium" }
            }
          >
            <Box pad={{ top: "large" }} />
            <Box
              pad={{
                top: "large",
                bottom: "none",
                horizontal: "small",
              }}
            >
              <Box pad={{ top: "medium", bottom: "none" }}>
                <Box
                  align={size === "small" ? "center" : "start"}
                  pad={{ bottom: "large" }}
                >
                  <PeriPlusLogo style={{ width: 130 }} />
                </Box>

                <Box direction="row" wrap>
                  <ItemBox size={size}>
                    <FooterTitle color="white" size={size}>
                      footer:products
                    </FooterTitle>
                    <Link
                      label="weDeclare"
                      to="https://www.wedeclare.ch"
                      style={footerLink}
                    />
                    <Link
                      label="spoor"
                      to="https://www.spoortracking.ch"
                      style={footerLink}
                    />
                  </ItemBox>

                  <ItemBox size={size}>
                    <FooterTitle color="white" size={size}>
                      footer:company
                    </FooterTitle>

                    <Link
                      label="support"
                      to="https://outlook.office365.com/owa/calendar/PeriPlus1@periplus.ch/bookings/s/f92sUYR6m0GKU5wz68I9bw2"
                      style={footerLink}
                    />
                    <Link
                      internal
                      to="../../disclaimer"
                      label="disclaimer"
                      style={footerLink}
                    />
                  </ItemBox>

                  <ItemBox size={size}>
                    <FooterTitle color="white" size={size}>
                      footer:contact
                    </FooterTitle>

                    <PText style={{}}>{street}</PText>
                    <PText>
                      {zip} {place}
                    </PText>
                    <PText>{`footer:${country}`}</PText>
                    <PText>{uid}</PText>
                  </ItemBox>
                </Box>

                <Box
                  direction={size === "small" ? "column" : "row-reverse"}
                  justify="between"
                  align="center"
                  wrap={true}
                  margin={{ top: "large" }}
                >
                  <Box direction="row" justify={centerJustificationRow(size)}>
                    <IconLink route="https://twitter.com/PeriPlusAG">
                      <Twitter color="#B1B4C0" />
                    </IconLink>
                    <IconLink route="https://linkedin.com/company/periplus-ag">
                      <LinkedinOption color="#B1B4C0" />
                    </IconLink>
                  </Box>
                  <Box style={{ opacity: "0.8", filter: "hue-rotate(45deg)" }}>
                    <TrustpilotReviews
                      language="en"
                      culture="US"
                      theme="dark"
                      height="24px"
                      width="inherit"
                    />
                  </Box>
                  <Box
                    align={centerAlignCol(size)}
                    direction="column"
                    justify="center"
                    pad={{ vertical: "medium" }}
                  >
                    <Text size="xsmall" color="#B1B4C0">
                      © {new Date().getFullYear()} PeriPlus AG. All rights
                      reserved.
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Footer
