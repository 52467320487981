import React from "react"
import { Link as GatsbyLink } from "gatsby"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

import { theme } from "../../styles/theme"

const LinkParagraph = styled.p`
  color: ${props => props.color};
  text-decoration: none;
  width: fit-content;
  z-index: 2;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  font-weight: 500;

  &:visited {
    color: ${props => props.color};
    text-decoration: none;
  }

  &:hover {
    color: ${props =>
      props.whiteBackground ? theme.global.colors.brand : "white"};
    text-decoration: none;
  }

  &:active {
    color: ${props => props.color};
  }
`

const Link = ({
  children,
  label,
  whiteBackground = true,
  color = "dark",
  to = "/",
  ...rest
}) => {
  const { t } = useTranslation()
  const isPartiallyActive = ({ isCurrent }) => {
    return isCurrent ? { className: "active" } : null
  }

  const internal = /^\/(?!\/)/.test(to)
  const setChildrenVariant = label ? (
    <LinkParagraph color={color} whiteBackground={whiteBackground} {...rest}>
      {t(`navigation:${label}`)}
    </LinkParagraph>
  ) : (
    children
  )

  if (internal) {
    return (
      <GatsbyLink
        getProps={isPartiallyActive}
        to={to}
        style={{ textDecoration: "none", display: "flex" }}
      >
        {setChildrenVariant}
      </GatsbyLink>
    )
  }
  return (
    <a href={to} {...rest}>
      {setChildrenVariant}
    </a>
  )
}

export default Link
