import React, { useRef, useState } from "react"
import styled from "styled-components"
import { Box, Drop } from "grommet"

import Link from "../Link/Link"
import { theme } from "../../styles/theme"
import { P } from "../Typography"

const LinkBox = styled(Box)`
  &:hover {
    filter: opacity(0.7);
    transition: 0.3s;
  }
`

const ProductBox = ({ linkData }) => {
  return (
    <Link
      to={linkData.path}
      target="_blank"
      rel="noopener noreferrer"
      key={linkData.title}
    >
      <LinkBox pad="xsmall" direction="row" justify="start">
        <Box justify="center" margin={{ right: "20px" }}>
          {linkData.icon}
        </Box>

        <Box>
          <P margin="0" bold size="small">
            {linkData.title}
          </P>
          <P margin="0" size="small">
            {linkData.linkDesc}
          </P>
        </Box>
      </LinkBox>
    </Link>
  )
}

const DropDown = ({ data, label, whiteBackground, color = "white" }) => {
  const [over, setOver] = useState()
  const ref = useRef()

  return (
    <Box
      ref={ref}
      onMouseOver={() => setOver(true)}
      onMouseLeave={() => setOver(false)}
      style={{ cursor: "pointer" }}
    >
      <Box pad="medium" justify="center">
        <Link label={label} whiteBackground={whiteBackground} color={color} />
      </Box>

      {ref.current && over && (
        <Drop
          align={{ top: "bottom" }}
          target={ref.current}
          style={{ marginTop: "-20px" }}
          plain
          onClickOutside={() => setOver(false)}
        >
          <Box
            margin={{ horizontal: "large", bottom: "large", top: "small" }}
            pad="small"
            background="white"
            round="xsmall"
            style={{ boxShadow: theme.global.shadows.float }}
          >
            {data.map(item => (
              <ProductBox linkData={item} />
            ))}
          </Box>
        </Drop>
      )}
    </Box>
  )
}

export default DropDown
